var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comments-settings"},[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveChannelConfig}},[_vm._v(" "+_vm._s(_vm.$t('save_active_channel_config'))+" ")])]},proxy:true}])}),_c('a-card',[_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'disable_message_in_group',
          'translationParams': [ _vm.$store.getters.getBoardById(_vm.$route.params.id).title ]
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'disable_join_to_group',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'comments_only_for_subscribers',
          'translationParams': [ _vm.$store.getters.getBoardById(_vm.$route.params.id).title ]
        },
      }}}),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'spam_comment_timeout',
        }
      }}}),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'post_comments_max_age',
        }
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'hasMedia': true,
          'key': 'comments_warn_message',
          'availableButtonsTypes': _vm.messageEditorButtons,
          'base-api-url': 'api.presscode.app/cabinet',
          'targetToUpload': function () {
            return {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
            }
          },
          'buttonsHelpView': _vm.EditorButtonsHelpView,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }