






























































































import { hiddenTargetTypeOptions } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import HiddenTargetTypesViews
  from '@/components/ProjectButtons/includes/components/HiddenTargetTypesViews/HiddenTargetTypesViews.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import { LimitedSubscriptionLinkButtonStruct } from '@/components/ProjectButtons/LimitedSubscriptionLinkButton/index'

@Component({
  components: {
    HiddenTargetTypesViews,
    ValidationObserver,
  },
})
export default class LimitedSubscriptionLinkButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: LimitedSubscriptionLinkButtonStruct

  @Prop({ 'type': Function }) readonly getArticlesFn!: () => Promise<Array<SelectOptionData>>

  @Prop({ type: Function }) getHasChannelsContentSubscription!: () => boolean

  @Watch('model.subscribedTargetType')
  onSubscribedTargetTypeChange(): void {
    this.model.subscribedTargetValue = ''
  }

  @Watch('model.unsubscribedTargetType')
  onUnsubscribedTargetTypeChange(): void {
    this.model.unsubscribedTargetValue = ''
  }

  currentTab = 1

  channelContentType

  async hiddenTargetOptions(): Promise<Array<SelectOptionData>> {
    return hiddenTargetTypeOptions().map((o) => {
      return {
        label: this.$t(o.label).toString(),
        value: o.value
      }
    })
  }
}
