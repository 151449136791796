



















































































import { InputSetups } from '../../mixins/input-setups'
import PageTitle from '@/components/PageTitle.vue'
import LimitedSubscriptionLinkButton from '@/components/ProjectButtons/LimitedSubscriptionLinkButton'
import { LimitedSubscriptionType } from '@/includes/types/Channel.types'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { AvailableButtonsTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    PageTitle,
  },
  data() {
    return {
      EditorButtonsHelpView,
    }
  }
})
export default class CommentsSettings extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  get messageEditorButtons(): AvailableButtonsTypes {
    return this.getDefaultButtons({
      moreProButtons: [
        new LimitedSubscriptionLinkButton({
          i18n: this.$i18n,
          getArticlesFn: async () => {
            if (!this.$store.state.articlesState.articles) {
              await this.$store.dispatch('requestArticles')
            }

            return this.$store.getters.articlesOptions
          },
          getHasChannelsContentSubscription: () => this.$store.state.channelsState.activeChannel!.limited_subscription_type === LimitedSubscriptionType.Content
        })
      ]
    })
  }

  saveChannelConfig(): void {
    this.$store.dispatch('save_active_channel_config', this.$route)
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  mounted(): void {
    this.$store.dispatch('AdminTemplate/showSaveConfigButton')
  }
}
