import { UserActivationAction } from '@/includes/types/PmConfig.types'

export enum ClearUsersRequestActions {
	Create = 'CreateRequest',
	Confirm = 'ConfirmRequest',
	Status = 'StatusRequest',
	Active = 'ActiveRequest',
	Cancel = 'CancelRequest'
}

export enum TargetType {
	Channel = 'Channel',
	TG = 'InviteLink',
	Press = 'InviteLinkCode'
}

export enum RequestStatus {
	Complete = 'complete',
	Process = 'process',
	Started = 'started',
	Stop = 'stopped',
	Reject = 'rejected',
	Wait = 'wait'
}

export type ClearUsersRequestBody = ClearUsersRequestStatusBody | ClearUsersRequestConfirmBody | ClearUsersRequestCreateBody | ClearUsersRequestActiveBody | ClearUsersRequestCancelBody

export type ClearUsersRequestActiveBody = {
	action: ClearUsersRequestActions.Active
	board_key: string
}

export type ClearUsersRequestStatusBody = {
	action: ClearUsersRequestActions.Status
	key: string
}

export type ClearUsersRequestConfirmBody = {
	action: ClearUsersRequestActions.Confirm
	key: string
	users: number
}

export type ClearUsersRequestCreateBody = {
	action: ClearUsersRequestActions.Create
	board_key: string
	target: {
		type: string
		channel_id: string
	}
	only_join_first_time: boolean
	from: string
	to: string
}

export type ClearUsersTargetTypes = TGLinkTarget | ChannelTarget | PressLinkTarget

export type ChannelTarget = {
	type: TargetType.Channel
	channel_id: string
}

export type TGLinkTarget = {
	type: TargetType.TG
	link: string
	channel_id: string
}

export type PressLinkTarget = {
	type :TargetType.Press
	code: string
	channel_id: string
}

export type ClearUsersRequestCancelBody = {
	action: ClearUsersRequestActions.Cancel
	key: string
	board_key: string
}

export type ClearUsersGetActiveRequestResponse = {
	items: Array<ClearUsersRequest>
}

export type ClearUsersRequest = {
	users: number
	success: number
	fail: number
	key: string
	channel_id: number
	from: string
	to: string
	status: RequestStatus
}

export enum LimitedSubscriptionType {
	None = 'None',
	Channel = 'Channel',
	Comments = 'Comments',
	Content = 'Content'
}

export type LimitedSubscriptionNotify = Record<number, UserActivationAction>

export type LimitedSubscriptionConfig = {
	from: string
	to: string
	notify: LimitedSubscriptionNotify | null
	goodbye: UserActivationAction | null
}