var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('div',{staticClass:"limited-subscription-link"},[(!_vm.getHasChannelsContentSubscription())?_c('a-alert',{staticClass:"mx-2 my-2",attrs:{"message":_vm.$t('limited_subscription_link_error'),"type":"error","show-icon":""}}):_vm._e(),_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'limited_subscription_link_button_',
          'validation': 'required',
          'saveFieldKey': 'text_limited_subscription_button',
          'disabled': !_vm.getHasChannelsContentSubscription(),
        },
      }}}),_c('div',{staticClass:"select-active flex flex-col mx-2"},[_c('a-button',{class:{ active: _vm.currentTab === 1 },on:{"click":function($event){_vm.currentTab = 1}}},[_vm._v(" "+_vm._s(_vm.$t('hidden_subscriber_link_subscribed'))+" ")]),_c('a-button',{staticClass:"my-2",class:{ active: _vm.currentTab === 2 },on:{"click":function($event){_vm.currentTab = 2}}},[_vm._v(" "+_vm._s(_vm.$t('hidden_subscriber_link_unsubscribed'))+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 1),expression:"currentTab === 1"}]},[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            model: _vm.model,
            'key': 'subscribedTargetType',
            'prefix': 'limited_subscription_link_button_',
            'validation': 'required',
            'clearable': false,
            'options': _vm.hiddenTargetOptions,
            'disabled': !_vm.getHasChannelsContentSubscription(),
          },
        }}}),_c('hidden-target-types-views',{attrs:{"model-key":'subscribedTargetValue',"hidden-target-type":_vm.model.subscribedTargetType,"get-articles-fn":_vm.getArticlesFn,"disabled":!_vm.getHasChannelsContentSubscription()},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 2),expression:"currentTab === 2"}]},[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            model: _vm.model,
            'key': 'unsubscribedTargetType',
            'prefix': 'limited_subscription_link_button_',
            'validation': 'required',
            'clearable': false,
            'options': _vm.hiddenTargetOptions,
            'disabled': !_vm.getHasChannelsContentSubscription(),
          },
        }}}),_c('hidden-target-types-views',{attrs:{"model-key":'unsubscribedTargetValue',"hidden-target-type":_vm.model.unsubscribedTargetType,"get-articles-fn":_vm.getArticlesFn,"disabled":!_vm.getHasChannelsContentSubscription()},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }