import buttonView from './LimitedSubscriptionLinkButton.vue'
import setupView from './LimitedSubscriptionLinkButtonSetupView.vue'

import { HiddenTargetType } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import Button, { ButtonStruct, BaseButtonProps } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

type LimitedSubscriptionLinkButtonDataType = {
  text: string
  subscribedTargetType: HiddenTargetType
  subscribedTargetValue: string
  unsubscribedTargetType: HiddenTargetType
  unsubscribedTargetValue: string
}

export default class LimitedSubscriptionLinkButton extends Button {
  name = 'LimitedSubscriptionLinkButton'

  setupView = setupView
  buttonView = buttonView

  data: LimitedSubscriptionLinkButtonDataType = this.getRawData()

  icon = '⏲️👆'

  constructor(props: BaseButtonProps<{ getArticlesFn: () => Promise<Array<SelectOptionData>>, getHasChannelsContentSubscription: () => boolean }>) {
    super(props)
  }

  getRawData(): LimitedSubscriptionLinkButtonDataType {
    return {
      text: '',
      subscribedTargetType: HiddenTargetType.Message,
      subscribedTargetValue: this.$i18n.t('initial_text_message_editor_limited_subscription_subscribed').toString(),
      unsubscribedTargetType: HiddenTargetType.Message,
      unsubscribedTargetValue: this.$i18n.t('initial_text_message_editor_limited_subscription_unsubscribed').toString(),
    }
  }
}

export class LimitedSubscriptionLinkButtonStruct extends ButtonStruct {
  subscribedTargetType: HiddenTargetType
  subscribedTargetValue: string
  unsubscribedTargetType: HiddenTargetType
  unsubscribedTargetValue: string

  constructor(struct: LimitedSubscriptionLinkButtonStruct) {
    super(struct)

    const {
      subscribedTargetType,
      subscribedTargetValue,
      unsubscribedTargetType,
      unsubscribedTargetValue,
    } = struct

    this.subscribedTargetType = subscribedTargetType
    this.subscribedTargetValue = subscribedTargetValue
    this.unsubscribedTargetType = unsubscribedTargetType
    this.unsubscribedTargetValue = unsubscribedTargetValue
  }
}
